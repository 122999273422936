<template>
  <div class="app-container">
    <CrudTable entity="Page" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        {
          field: 'name',
          minWidth: 300
        },
        'position',
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'inFooterMenuYN',
          label: 'page.inFooterMenu',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'template',
          label: 'page.template',
          minWidth: '170px',
          sortable: false,
          formatter: this.formatTemplateOption,
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('template'),
            valueField: 'code',
            labelField: 'description'
          }
        }
      ]
    };
  },
  methods: {
    formatTemplateOption(row, column, cellValue) {
      const option =
        cellValue !== null &&
        this.$store.getters['option/loadedOptions']('template').find(item => item.code === cellValue);
      return option && option.description;
    }
  }
};
</script>

<style scoped></style>
